<template>
	<div class="content--loading" v-if="loading">
		<filling-circle color="#057DCD" :size="42" />
	</div>
    <div class="settings-card" v-else>
		
		<div class="tabbedpanel">
			<div class="tabbedpanel__tabs">
				<div v-for="(role,index) in userRoles" :key="index" @click="setSelected(index)" class="tabbedpanel__tab" 
				:class="{'tabbedpanel__selected': isSelected(index)}">{{ role.name }}</div>
				
			</div>
			<div class="tabbedpanel__panel">
				<h1 class="tabbedpanel__title">Permissions</h1>
				<ul class="permissions" v-for="(permissions,name) in restructureUserRoleData(userRoles[selected]).permissions" :key="name">
					<li class="permissions__name">{{ name }}:</li>
					<li class="permissons__list"> 
						<div v-for="(permission,type) in permissions" :key="type">
							<label>
								<input type="checkbox" v-if="edit" v-model="editStateUserRole[`can_${type}_${name}`]" >
								<input type="checkbox" v-else v-model="userRoles[selected][`can_${type}_${name}`]" 	disabled="disabled">
								can {{ type.replaceAll('_',' ') }}
							</label>
						</div>
					</li>
				</ul>
				<div class="tabbedpanel__buttons">
					<button class="tabbedpanel__danger" v-show="!edit && !userRoles[selected].is_default" @click="deleteUserRole">	{{ deleting ? 'Deleting...' : 'Delete' }}</button>
					<button class="tabbedpanel__button" v-show="!edit && !userRoles[selected].is_default" @click="setEdit(true)">Edit</button>
					
					<button class="tabbedpanel__cancel" v-show="edit" @click="edit = false">cancel</button>
					<button class="tabbedpanel__button" v-show="edit" @click="updateUserRole(true)">
						{{ updating ? 'Saving...' : 'Save' }}
					</button>
				</div>
			</div>
		</div>
		<router-view />
	</div>
</template>

<script>
import FillingCircle from '@/components/Loaders/FillingCircle.vue'
export default {
	components:{FillingCircle},
	data(){
		return {
			permissions:['products','consignments','orders','sales',
						'account_tabs','expenses','staff','contacts','analyses','activity_log','other_settings'],
			selected:0,
			edit:false,
			editStateUserRole:{},
			loading:true,
			updating:false,
			deleting:false,
		}
	},
	
	computed:{
		userRoles(){
			return this.$store.state.userRoleSettings.userRoles
		},
		newRole(){
			return this.$store.state.userRoleSettings.newRole
		}
	},

	watch:{
		newRole(newVal){
			if(newVal){
				this.edit = true
				this.selected = this.userRoles.length - 1
			}
		}
	},

	methods:{
		isSelected(option){
			return option === this.selected
		},

		setEdit(edit){
			this.edit = edit
			this.editStateUserRole = {...this.userRoles[this.selected]}
		},

		setSelected(option){
			if (this.newRole) this.$store.commit({
				type:'setNewRole',
				newRole:false,
			})
			this.edit = false
			this.selected = option
		},

		updateUserRole(){
			this.updating = true,
			this.$store.dispatch(
				{type:'userRoleSettings/updateUserRole',
				userRoleObject: this.editStateUserRole,
				id: this.editStateUserRole.id})
			.then((response)=>{
				console.log(response)
				if (this.newRole) this.$store.commit({
					type:'setNewRole',
					newRole:false,
				})
				this.edit = false
				this.updating = false

			})
			.catch(error=>{
				this.updating = false
				console.log((error))
			})	
		},

		deleteUserRole(){
			this.deleting = true,
			this.$store.dispatch(
				{type:'userRoleSettings/deleteUserRole',
				id: this.userRoles[this.selected].id})
			.then((response)=>{
				console.log(response)

				if (this.newRole) this.$store.commit({
					type:'setNewRole',
					newRole:false,
				})
				this.edit = false
				this.deleting = false
				this.selected = this.userRoles.length - 1

			})
			.catch(error=>{
				this.deleting = false
				console.log((error))
			})	
		},

		restructureUserRoleData(inputData){

			let  rolesInformation= Object.keys(inputData)
			.filter(key => !key.includes('can') ).reduce((obj,key)=>{
				obj[key] = inputData[key]
				return obj
			},{})

			let rolesPermission = {}


			for(let permission of this.permissions){
				let  property = Object.keys(inputData)
				.filter(key => key.includes(permission) ).reduce((obj,key)=>{
				
					if (key.includes('view')) obj['view'] = inputData[key]
					else if (key.includes('create')) obj['create'] = inputData[key]
					else if (key.includes('manage')) obj['manage'] = inputData[key]
					else if (key.includes('make')) obj['make'] = inputData[key]
					else if (key.includes('refund')) obj['refund'] = inputData[key]
					else if (key.includes('regulate')) obj['regulate'] = inputData[key]
					else if (key.includes('edit')) obj['edit_prices_for'] = inputData[key]
					return obj
				},{})

				rolesPermission[permission] = property
			}

			let rolesData = rolesInformation
			rolesData['permissions'] = rolesPermission

			return rolesData

		}
	},
	mounted(){
		this.$store.dispatch({type:'userRoleSettings/loadUserRoles'})
		.then(()=>{
			this.loading = false
			console.log(this.edit)


		})
		.catch(error=>console.log((error)))
	}
}
</script>



<style>

	.tabbedpanel{
		display:grid;
		grid-template-columns: min-content auto;
		border:solid 1px #ccc;

	}

	.tabbedpanel__title{
		font-weight: bold;
	}


	.tabbedpanel__tabs{
		background: #F3F5F7;
		border-right:solid 1px #ccc;
		overflow-y:scroll;
	}

	.tabbedpanel__tab{
		position: relative;
		cursor:pointer;
		user-select: none;
		text-align: right;
		padding:2em;
		border-bottom:solid 1px #ccc;
	}

	.tabbedpanel__selected{
		background: #fff;
		transition: .3s background ease;
	}

	.tabbedpanel__selected::before{
		position: absolute;
		top:0;
		bottom: 0;
		right:0;
		content: "";
		width: 5px;
		background: var(--bg-primary);
	}
/* 
	.tabbedpanel__tabs div:first-child{
		border-top:none;
	} */

	.tabbedpanel__panel{

		padding:2em 4em;
		padding-right: 0; 
	}

	.permissions{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		list-style: none;
		padding:1em 0;
	}

	.permissions > li{
		margin-right:2em
	}


	.permissions label{
		margin-right:2em
	}

	.permissions__name{

		min-width: 13ch;

	}

	.permissons__list{
		display: flex;
		flex-wrap: wrap;
	}

	.tabbedpanel__buttons{
		display: flex;
		flex-wrap: wrap-reverse;
		padding:1em 0;
		
	}

	.tabbedpanel__button,.tabbedpanel__cancel,.tabbedpanel__danger{
		color:#fff;
		background:var(--bg-dark);
		border:none;
		margin-left: .5em;
		margin-bottom: .5em;
		padding:.5em 2.5em;
	}

	.tabbedpanel__danger{
		background: var(--bg-danger);
	}
	
	.tabbedpanel__cancel{
		color: var(--bg-dark);
		border: solid 1px var(--bg-dark);
		background: transparent;
		box-shadow: none;
	}

	
	.tabbedpanel__checkbox{
		background:#222;
	}

</style>